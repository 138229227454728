<template>
  <div v-if="visible" :class="alertClasses" role="alert">
    <div class="flex items-center whitespace-pre-line gap-3 py-1">
      <slot name="icon">
        <AlertCircle :class="iconClasses" />
      </slot>
      <span v-if="text" :class="textClasses">
        {{ text }}
      </span>
      <button
        v-if="!inline && closable"
        type="button"
        :class="closeClasses"
        aria-label="Close"
        @click="onCloseClick">
        <span class="sr-only">Dismiss</span>
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
    <div :class="contentClasses">
      <slot />
    </div>
    <div v-if="$slots.actions" class="inline-flex items-center">
      <slot name="actions" />
    </div>
    <button
      v-if="inline && closable"
      type="button"
      :class="closeClasses"
      aria-label="Close"
      @click="onCloseClick">
      <span class="sr-only">Dismiss</span>
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
      </svg>
    </button>
  </div>
</template>
<script lang="ts" setup>
import type { PropType } from 'vue';
import { useAlertClasses } from './composables/useAlertClasses';
import { ref, toRefs } from 'vue';
import type { AlertType } from './types';
import { AlertCircle } from 'lucide-vue-next';

const props = defineProps({
  type: {
    type: String as PropType<AlertType>,
    default: 'info',
  },
  text: {
    type: String,
    default: '',
  },
  closable: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Boolean,
    default: true,
  },
  border: {
    type: Boolean,
    default: true,
  },
  inline: {
    type: Boolean,
    default: true,
  },
});

const { alertClasses, textClasses, iconClasses, closeClasses, contentClasses } =
  useAlertClasses(toRefs(props));

const visible = ref(true);

const onCloseClick = () => {
  visible.value = false;
};
</script>
