import type { AlertType } from '../types';
import { computed } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';

const defaultAlertClasses = 'px-4 py-1 text-sm w-full';

const alertTextClasses: Record<AlertType, string> = {
  danger: 'text-red-600 caret-red-600',
  dark: 'text-secondary-950 caret-secondary-950',
  info: 'text-primary caret-primary',
  success: 'text-green-600 caret-green-600',
  warning: 'text-orange-500 caret-orange-500',
};

const iconFillClasses: Record<AlertType, string> = {
  danger: 'stroke-red-50 fill-red-500 ',
  dark: 'stroke-secondary-50 fill-secondary-950',
  info: 'stroke-primary-50 fill-primary',
  success: 'stroke-green-50 fill-green-500',
  warning: 'stroke-orange-50 fill-orange-500',
};

const alertTypeClasses: Record<AlertType, string> = {
  danger: 'bg-red-50',
  dark: 'bg-secondary-50',
  info: 'bg-primary-50',
  success: 'bg-green-50',
  warning: 'bg-orange-50',
};

const alertBorderClasses: Record<AlertType, string> = {
  danger: 'border border-red-300 rounded-lg',
  dark: 'border border-secondary-950 rounded-lg',
  info: 'border border-primary-300 rounded-lg',
  success: 'border border-green-300 rounded-lg',
  warning: 'border border-orange-300 rounded-lg',
};

const defaultCloseButtonClasses =
  'mr-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8';
const closeButtonClasses: Record<AlertType, string> = {
  danger: 'hover:bg-red-100 text-red-500',
  dark: 'hover:bg-secondary-100 text-secondary-950',
  info: 'hover:bg-primary-100 text-primary-600',
  success: 'hover:bg-green-100 text-green-600',
  warning: 'hover:bg-orange-100 text-orange-500',
};

export type UseAlertClassesProps = {
  type: Ref<AlertType>;
  border: Ref<boolean>;
  icon: Ref<boolean>;
  inline: Ref<boolean>;
  text: Ref<string>;
};

export function useAlertClasses(props: UseAlertClassesProps): {
  alertClasses: Ref<string>;
  textClasses: Ref<string>;
  closeClasses: Ref<string>;
  contentClasses: Ref<string>;
  titleClasses: Ref<string>;
  iconClasses: Ref<string>;
} {
  const alertClasses = computed<string>(() => {
    return classNames(
      defaultAlertClasses,
      alertTypeClasses[props.type.value],
      textClasses.value,
      iconClasses.value,
      props.border.value ? alertBorderClasses[props.type.value] : 'rounded-lg', // rounded only if no border
      props.inline.value ? 'flex items-center gap-2' : '',
    );
  });

  const textClasses = computed<string>(() => {
    return classNames(alertTextClasses[props.type.value]);
  });

  const closeClasses = computed<string>(() => {
    return classNames(
      defaultCloseButtonClasses,
      closeButtonClasses[props.type.value],
    );
  });

  const iconClasses = computed<string>(() => {
    return classNames(iconFillClasses[props.type.value]);
  });

  const contentClasses = computed<string>(() => {
    if (!props.inline.value) return classNames('mt-2 mb-4');
    if (!props.icon.value && !props.text.value) return '';
    return classNames(!props.text.value ? 'ms-3 w-full' : 'ms-1');
  });

  const titleClasses = computed<string>(() => {
    if (!props.icon.value || !props.inline.value)
      return classNames(
        'font-medium',
        !props.inline.value ? 'text-lg ml-2' : '',
      );
    return classNames('font-medium ml-3', !props.inline.value ? 'text-lg' : '');
  });

  return {
    alertClasses,
    textClasses,
    closeClasses,
    contentClasses,
    titleClasses,
    iconClasses,
  };
}
